const indexDB = window.indexedDB;

const DB_NAME = 'sales_planning';
const DB_VERSION = 19;
const STORE_NAME = 'data';
const ANNUAL_STORE_NAME = 'annualdata';
type DataItem = { [key: string]: any }; // Define a type for the data being stored

export const initializeDB = () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.log("error");
      console.error('IndexedDB error:', event);
      reject(request.error);
    };

    request.onsuccess = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'key' });
      }
      if ( !db.objectStoreNames.contains(ANNUAL_STORE_NAME)) {
        db.createObjectStore(ANNUAL_STORE_NAME, { keyPath: 'key' });
      }
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      console.log("degrade");
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'key' });
      }
      if ( !db.objectStoreNames.contains(ANNUAL_STORE_NAME)) {
        db.createObjectStore(ANNUAL_STORE_NAME, { keyPath: 'key' });
      }
    };
  });
};

export const saveuserLaststate = async (data: { key: string; userLaststate: any }): Promise<void> => {
  const db = await initializeDB();
  const transaction = db.transaction([STORE_NAME], 'readwrite');
  const store = transaction.objectStore(STORE_NAME);

  store.put(data);

  transaction.oncomplete = () => {
    // console.log('Data saved to IndexedDB');
  };

  transaction.onerror = (event) => {
    console.error('Error saving to IndexedDB:', event);
  };
};

export const saveData = async (data: DataItem | DataItem[]) => {
  const db = await initializeDB();
  const transaction = db.transaction([STORE_NAME], 'readwrite');
  const store = transaction.objectStore(STORE_NAME);

  store.put(data);

  transaction.oncomplete = () => {
    // console.log('Data saved to IndexedDB',data);
  };

  transaction.onerror = (event) => {
    // console.error('Error saving to IndexedDB:', event);
  };
  
};

export const annualsaveData = async (data: DataItem | DataItem[]) => {
  const db = await initializeDB();
  const transaction = db.transaction([ANNUAL_STORE_NAME], 'readwrite');
  const store = transaction.objectStore(ANNUAL_STORE_NAME);

  store.put(data);
  

  transaction.oncomplete = () => {
    // console.log('Data saved to IndexedDB',data);
  };

  transaction.onerror = (event) => {
    // console.error('Error saving to IndexedDB:', event);
  };
};


export const saveDataByArray = async (dataArray: { [key: string]: any }[]) => {
  try {
    const db = await initializeDB();
    const transaction = db.transaction([STORE_NAME], "readwrite");
    const store = transaction.objectStore(STORE_NAME);

    // Iterate over each item in the array
    for (const data of dataArray) {
      // Save the data object, do not pass the key since it is in-line
      store.put(data);  // Let IndexedDB handle the key automatically
    }

    // Wrap transaction completion in a Promise
    await new Promise<void>((resolve, reject) => {
      transaction.oncomplete = () => {
        console.log("Data saved successfully to IndexedDB:");
        resolve();
      };

      transaction.onerror = () => {
        const errorReason = transaction.error instanceof Error ? transaction.error.message : "Unknown error during IndexedDB transaction";
        reject(new Error(errorReason));
      };

      transaction.onabort = () => {
        const errorReason = transaction.error instanceof Error ? transaction.error.message : "Transaction was aborted";
        reject(new Error(errorReason));
      };
    });
  } catch (error) {
    console.error("IndexedDB saveData error:", error);
    throw error; // Rethrow for upstream error handling
  }
};

export const getData = async (key: any): Promise<any[]> => {
  const db = await initializeDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, "readonly");
    const store = transaction.objectStore(STORE_NAME);

    const request = store.get(key);

    request.onsuccess = (event) => {
      const result = (event.target as IDBRequest).result;
      resolve(result || null); // Resolve null if no data is found
    };

    request.onerror = (event) => reject((event.target as IDBRequest).error);
  });
};

export const annualgetData = async (key: any): Promise<any[]> => {
  const db = await initializeDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(ANNUAL_STORE_NAME, "readonly");
    const store = transaction.objectStore(ANNUAL_STORE_NAME);

    const request = store.get(key);

    request.onsuccess = (event) => {
      const result = (event.target as IDBRequest).result;
      resolve(result || null); // Resolve null if no data is found
    };

    request.onerror = (event) => reject((event.target as IDBRequest).error);
  });
};

export const getuserLaststate = async (key: string): Promise<{ key: string; userLaststate: any[] } | null> => {
  const db = await initializeDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, "readonly");
    const store = transaction.objectStore(STORE_NAME);

    const request = store.get(key);

    request.onsuccess = (event) => {
      const result = (event.target as IDBRequest).result;
      resolve(result || null); // Resolve null if no data is found
    };

    request.onerror = (event) => reject((event.target as IDBRequest).error);
  });
};


export const clearAllData = async () => {
  const db = await initializeDB();
  const transaction = db.transaction([STORE_NAME], "readwrite");
  const objectStore = transaction.objectStore(STORE_NAME);
  const request = objectStore.clear();

  request.onsuccess = function() {
    console.log("All records cleared from the object store.");
  };
};


export const clearData = async (key: any) => {
  const db = await initializeDB();
  const transaction = db.transaction([STORE_NAME], 'readwrite');
  const store = transaction.objectStore(STORE_NAME);

  const request = store.delete(key);

  transaction.oncomplete = () => {
    console.log('Data cleared from IndexedDB');
  };

  transaction.onerror = (event) => {
    console.error('Error clearing from IndexedDB:', event);
  };

  request;
};

export const annualclearData = async (key: any) => {
  const db = await initializeDB();
  const transaction = db.transaction([ANNUAL_STORE_NAME], 'readwrite');
  const store = transaction.objectStore(ANNUAL_STORE_NAME);

  const request = store.delete(key);

  transaction.oncomplete = () => {
    console.log('Data cleared from IndexedDB');
  };

  transaction.onerror = (event) => {
    console.error('Error clearing from IndexedDB:', event);
  };

  request;
};